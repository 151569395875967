import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "timChambers",
  "categories": ["testing"],
  "date": "2021-07-29",
  "path": "/blog/test-like-you-fly-3",
  "summary": "How we conduct TLYF in concert with the universe",
  "title": "Test Like You Fly - The World Revolves Around You",
  "image": "./ogp.jpg",
  "seo": {
    "og": {
      "type": "article",
      "title": "Test Like You Fly - The World Revolves Around You",
      "description": "How we conduct TLYF in concert with the universe"
    },
    "twitter": {
      "card": "summary_large_image",
      "site": "@hintmedia"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In `}<a parentName="p" {...{
        "href": "https://hint.io/blog/test-like-you-fly-2"
      }}>{`Test Like You Fly - Part 2 - Basics`}</a>{`, we discussed the fundamentals of how you practice a TLYF strategy. No systems operate in such isolation that they are not reliant on some other code foundations. And those foundations rely on other foundations.`}</p>
    <h2>{`Environments and Configurations - Oh My!`}</h2>
    <p>{`Our applications don't live in a vacuum. They take advantage of the operating systems, databases, caches, networks, and other external services available today in the cloud deployment environment. Let's see what we should think about in regard to Testing Like We Fly.`}</p>
    <h3>{`Predefine and Test`}</h3>
    <ul>
      <li parentName="ul">{`Provisioning – Ensure existence/creation of directories`}</li>
      <li parentName="ul">{`Validate that dependencies (gems, npm packages, etc.) state compatibility with the environment, not just assume future compatibility`}</li>
      <li parentName="ul">{`Consistency – Verify language (Ruby, Rails, JavaScript, Database, etc.) versions and their dependencies being tested match those in our deployed environment.`}</li>
      <li parentName="ul">{`Environment variables (and defaults for lack thereof)`}</li>
      <li parentName="ul">{`IP usage and Port conflicts`}</li>
    </ul>
    <h3>{`Get Specific`}</h3>
    <p>{`Specificity is an invaluable ally in operating environments that have been confirmed through testing. Do not make the fatal inference: “It should work”.`}</p>
    <ul>
      <li parentName="ul">{`Bugs exist in all code.`}</li>
      <li parentName="ul">{`Bugs existed in code in older versions then you are using`}</li>
      <li parentName="ul">{`Bugs (especially unknown bugs) can be introduced in code you rely on if it is `}<em parentName="li">{`beyond`}</em>{` your tested version`}</li>
      <li parentName="ul">{`Upgrading is not only a requirement but a risk`}</li>
      <li parentName="ul">{`All environmental changes require retesting`}</li>
    </ul>
    <h3>{`Cautionary Tale - MySQL Version Upgrade`}</h3>
    <p>{`Let's say we have a database running MySQL 8.0.18. We are tasked with upgrading this database to MySQL 8.0.19. Based on semantic versioning, this looks like an innocent patch update. Unfortunately, this is far from the truth. In this update, the code was changed to assume that `}<inlineCode parentName="p">{`TINYINT(1)`}</inlineCode>{` should be treated as a boolean. However, this was NOT the case any longer if the column was `}<inlineCode parentName="p">{`UNSIGNED`}</inlineCode>{` when the schema was extracted. When schemas from MySQL 8.0.18 were dumped and loaded into another database running MySQL 8.0.19, these boolean columns became `}<inlineCode parentName="p">{`TINYINT(4)`}</inlineCode>{` columns. Existing databases were unaffected, but any new databases loading the old schemas signalled to applications that these columns were no longer boolean.`}</p>
    <p><strong parentName="p">{`Moral: Always test the full cycle with any change including downstream practices like data transfer/replication`}</strong></p>
    <h2>{`Networking - The Outside World Awaits`}</h2>
    <p>{`One of the most difficult contexts to emulate in testing is interfacing with networks. APIs with credentials that serve us in production should be secured from testing access. So how do we sufficiently TLYF while still abiding by network security best practices?`}</p>
    <h3>{`Start Yanking Cables`}</h3>
    <p>{`If network access is crucial, then taking the network down before and randomly during test execution provides critical insight into how your application handles these inevitable scenarios.`}</p>
    <ul>
      <li parentName="ul">{`Adjust response to degrade gracefully where possible`}</li>
      <li parentName="ul">{`Use gentle throttling to handle peaks`}</li>
      <li parentName="ul">{`Test on a variety of networks to detect `}<em parentName="li">{`hardcoded`}</em>{` expectations`}</li>
    </ul>
    <h3>{`Log In as Production`}</h3>
    <p>{`If possible, use or at least test using the same cloud credentials as will be used in production. This will ensure the accessible “user” context is tested. Testing Like You Fly in this respect gives us the confidence in knowing that if we can hit production cloud services in the test environment, the odds are good that we can hit them in production as well. Make absolutely sure that any test system you establish cannot access the production system directly - protect the production environment from mutation during tests.`}</p>
    <h3>{`Get a Bigger Measuring Tape`}</h3>
    <p>{`In the age of cloud computing, physical distance between your servers is often overlooked. Consider the availability needs and data processed by the servers physically closest to the user locations they service.`}</p>
    <ul>
      <li parentName="ul">{`Failover to other regions within same provider`}</li>
      <li parentName="ul">{`Failover to other providers`}</li>
      <li parentName="ul">{`Alternate DNS servers for DNS lookup`}</li>
    </ul>
    <p>{`Short but important considerations for all parties involved in launching a successful application. Next up is Timing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      